import React, { useState } from "react"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const [darkModeActive, setDarkMode] = useState(true)
  const handleOnClick = () => setDarkMode(!darkModeActive)
  return (
    <main className={`${darkModeActive ? 'dark' : ''}`}>
      <Helmet>
        <title>Personal Site</title>
      </Helmet>
      <div className="transition-colors duration-300  dark:bg-gray-800 text-gray-600 dark:text-gray-400">
        <div className="absolute right-3 top-2">
              <button className="bg-gray-800text-white p-2" onClick={handleOnClick}>{!darkModeActive ? "Go dark" : "Shine bright"}</button>
        </div>
        <div className="container mx-auto font-mono">
          <div className="flex flex-col h-screen">
            <div className="m-auto text-center">
              <h1 className="text-2xl">Miguel Contreras</h1>
              <h2 className="text-xl">Developer</h2>
              <div className="flex flex-row my-1 justify-evenly">
                <p>
                  <a className="hover:text-gray-400 dark:hover:text-gray-200" href="https://github.com/mike-contreras" target="_blank" rel="noopener noreferrer">Github</a>
                </p>
                <span>|</span>
                <p>
                  <a className="hover:text-gray-400 dark:hover:text-gray-200" href="https://www.linkedin.com/in/dwmike" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </p>
              </div>
            </div>
              {darkModeActive && <p className="absolute text-sm italic left-2 bottom-2">"Hey who turned off the lights?"</p>}
          </div>
        </div>
      </div>

    </main>
  )
}

export default IndexPage
